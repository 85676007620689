import React, { createElement, useEffect, useState } from 'react';
import axios from 'axios';
import { FaSearchPlus, FaFileDownload } from "react-icons/fa";
import { PiDnaFill } from "react-icons/pi";
import Data from './Data/Data';
import { defer, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


const Downloader = () => {
    const [url, setUrl] = useState('');
    const [quality, setQuality] = useState('highest');
    const [resolution, setResolution] = useState("");

    const [downloadLink, setDownloadLink] = useState(null);
    const [loading, setLoading] = useState(false);
    const naviagte = useNavigate();

    const [showSearchBox, setShowSearchBox] = useState(false);

    const start = () => {
        setShowSearchBox(true);
    }

    const [allFomrats, setAllFormats] = useState([]);

    const [mainData, setMainData] = useState(null);

    useEffect(() => {
        if (localStorage.getItem("mainData")) {
            setMainData(JSON.parse(localStorage.getItem("mainData")));

            let data = JSON.parse(localStorage.getItem("mainData"));


            const groupedFormats = data.formats.reduce((acc, curr) => {
                const existingFormat = acc.find(item => item.qualityLabel === curr.qualityLabel);
                if (existingFormat) {
                    existingFormat.variants.push(curr);
                } else {
                    acc.push({
                        qualityLabel: curr.qualityLabel,
                        variants: [curr]
                    });
                }
                return acc;
            }, []);

            setAllFormats(groupedFormats);

            console.log(groupedFormats);

        }
    }, [localStorage])



    function secondsToTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    const handleSubmit = () => {
        if(resolution.toString().trim() !== ""){
        let selectedFormat = allFomrats.find(x => x.qualityLabel === resolution);

        let selectVarient = selectedFormat.variants.find(x => x.container === "mp4");

        const a = document.createElement("a");

        // Set the download URL to the variant's URL
        a.href = selectVarient.url;

        // Open the download link in a new tab
        a.target = "_blank";

        // Trigger a click on the anchor element to start the download
        a.click();
        }else{
            toast("please select resolutaion..!" , {
                type: 'error'
            })
        }
    }


    const convertNext = () => {
        localStorage.removeItem("mainData");
        naviagte("/");
    }


    return (
        <div className='youtube-downloader'>
            <div className='youtube-background-image'>
                <img src="/image/YoutubeBackgroundImage.svg" />
            </div>
            <div className="youtube_downloader_container">
                <div className="text">
                    <h1>Download Youtube mp3, convert Youtube to mp3 online</h1>
                    <p>Tool download mp3 from Youtube, convert Youtube to mp3 high quality</p>
                </div>

                {
                    (mainData) && (
                        <div className="All-Data">
                            <div className='downloader'>
                                <div className="vedio-thumbinal">
                                    <img src={mainData?.videoThumbnail[3].url} alt="thumbnail" />
                                </div>
                                <div className="vedio-details">
                                    <div className="vedio-discription">
                                        <h4>{mainData.videoTitle}</h4>
                                        <span>{secondsToTime(mainData.videoLength)}</span>
                                    </div>
                                    <div className="downloader-buttons">
                                        <div>
                                            <select id="resolution" value={resolution} onChange={(e) => setResolution(e.target.value)}>
                                                <option value="" selected>Select</option>
                                                {
                                                    allFomrats.map((item) => (
                                                        <option value={item.qualityLabel} >{item.qualityLabel}</option>
                                                    ))
                                                }
                                            </select><br /><br />
                                        </div>
                                        <button onClick={() => handleSubmit()}>Download</button>
                                        <button onClick={() => convertNext()}>Another Video</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                }





                <h1>How to download Youtube video?</h1>
            </div>
            <div className="how-to-download">
                <div className="card">
                    <span><FaSearchPlus /></span>
                    <p>Paste YouTube url or enter keywords into the search box.</p>
                </div>
                <div className="card">
                    <span><FaFileDownload /></span>
                    <p>Choose output MP4 or MP3 format you want to convert and click "Download" button.</p>
                </div>

                <div className="card">
                    <span><PiDnaFill /></span>
                    <p>Wait until the conversion is completed and download the file. Very easy and fast..</p>
                </div>
            </div>
            <div className="Faq">
                <h1>FAQ - Kuick Convertor YouTube Downloader</h1>

                {
                    Data.map((item) => {
                        return (
                            <div className="bottom-text">
                                <h4>{item.Question}</h4>
                                <p>{item.Answer}</p>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    );
};

export default Downloader;
