import React from 'react';
import YoutubeDownloader from './YoutubeDownloader';
import Navbar from './Navbar';
import { Route, Routes } from 'react-router-dom';
import Downloader from './Downloader';
import YoutubeMP3 from './YoutubeMp3';
import Mp3Downloader from './Mp3Downloader';

const App = () => {
  return (
    <div>

      <Navbar />
      <Routes>
        <Route path='/' element={<YoutubeDownloader />} />
        <Route path="/Youtube-mp3-downloader" element={<YoutubeMP3 />} />
        <Route path="/downloader-mp3" element={<Mp3Downloader />} />
        <Route path='/downloader' element={<Downloader/>} />
      </Routes>
    </div>
  );
}

export default App;
