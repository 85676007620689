import React, { useState } from 'react';
import axios from 'axios';
import { FaSearchPlus, FaFileDownload } from "react-icons/fa";
import { PiDnaFill } from "react-icons/pi";
import Data from './Data/Data';
import Downloader from './Downloader';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const YoutubeMP3 = () => {
    const [url, setUrl] = useState('');
    const [format, setFormat] = useState('video');
    const [quality, setQuality] = useState('highest');
    const [resolution, setResolution] = useState('highest');
    const [videoInfo, setVideoInfo] = useState(null);
    const [downloadLink, setDownloadLink] = useState(null);
    const navigate = useNavigate();

    const [showSearchBox, setShowSearchBox] = useState(false);

    // ============
    const [searchQuery, setSearchQuery] = useState("");
    const [data, setData] = useState([]);




    const [loading, setLoading] = useState(false);




    function ytVidId(url) {
        var p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
        return (url.match(p)) ? RegExp.$1 : false;
    }


    const handleSubmit = (e) => {
        e.preventDefault();

        if (searchQuery.toString().trim() !== "") {
            if (ytVidId(searchQuery)) {
                setLoading(true);
                axios.post("https://youtube-downloader-api-murex.vercel.app/download", {
                    "videoUrl": searchQuery,
                    "downloadOptions": {
                        "format": "audio"
                    }
                }).then(res => {
                    setLoading(false);
                    setData(res.data);
                    localStorage.setItem("mainData" , JSON.stringify(res.data));
                    navigate("/downloader-mp3");
                })
            } else {
                toast("Youtube url is not valid....!", {
                    type: 'error',
                    position: 'bottom-right'
                });
            }

        } else {
            toast("Youtube url Required....!", {
                type: 'error',
                position: 'bottom-right'
            });
        }
    }

    return (
        <div className='youtube-downloader'>
            <div className='youtube-background-image'>
                <img src="/image/YoutubeBackgroundImage.svg" />
            </div>
            <div className="youtube_downloader_container">
                <div className="text">
                    <h1>YouTube Downloader  </h1>
                    <p>Convert and download YouTube videos in MP3, MP4, 3GP for free</p></div>

                <form onSubmit={handleSubmit} className='form'>
                    <div className="search-box">
                        <input type="text" id="url" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search or paste your Youtube link here...." /><br /><br />
                        <button className='Convert-btn'>
                            {
                                loading ? (
                                    <div className='loading'>
                                        <i class="fa fa-circle-o-notch fa-spin"></i>
                                    </div>
                                ) : "Convert"
                            }

                        </button>
                    </div>
                </form>


                <div id="videoInfo">
                    {videoInfo && (
                        <div>
                            <h2>{videoInfo.title}</h2>
                            <img src={videoInfo.thumbnail_url} alt="Video Thumbnail" />
                        </div>
                    )}
                </div>

                {downloadLink && (
                    <div>
                        <a href={downloadLink.href} download={downloadLink.download}>{downloadLink.textContent}</a>
                    </div>
                )}

                <h1>How to download Youtube video?</h1>
            </div>
            <div className="how-to-download">
                <div className="card">
                    <span><FaSearchPlus /></span>
                    <p>Paste YouTube url or enter keywords into the search box.</p>
                </div>
                <div className="card">
                    <span><FaFileDownload /></span>
                    <p>Choose output MP4 or MP3 format you want to convert and click "Download" button.</p>
                </div>

                <div className="card">
                    <span><PiDnaFill /></span>
                    <p>Wait until the conversion is completed and download the file. Very easy and fast..</p>
                </div>
            </div>
            <div className="Faq">
                <h1>FAQ - Kuick Convertor YouTube Downloader</h1>

                {
                    Data.map((item) => {
                        return (
                            <div className="bottom-text">
                                <h4>{item.Question}</h4>
                                <p>{item.Answer}</p>
                            </div>
                        )
                    })
                }

            </div>
        </div>
    );
};

export default YoutubeMP3;
