import React, { useState } from 'react';
import { FiMenu } from "react-icons/fi";
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav>
            <div className="nav_container">
                <div className="logo">
                    <img src="./image/Kuick__1.png" alt="logo" />
                </div>
                <div className="Menu_right">
                    <ul>
                        <Link to='/' className='link'>YouTube Downloader</Link>
                        <Link to='/Youtube-mp3-downloader' className='link'>YouTube to MP3</Link>
                        {/* <Link to='/YouTubetoMP4' className='link'>YouTube to MP4</Link> */}
                    </ul>
                </div>
                <div className="Menu-btn" onClick={toggleMenu}>
                    <FiMenu />
                </div>
                {isOpen && (
                    <ul className="menu">
                        <Link to='/' className='link'>YouTube Downloader</Link>
                        <Link to='/YouTubetoMP3' className='link'>YouTube to MP3</Link>
                        <Link to='/YouTubetoMP4' className='link'>YouTube to MP4</Link>
                    </ul>
                )}
            </div>
        </nav>
    );
}

export default Navbar;
